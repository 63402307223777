<template>
		<v-alert
			color='#607d8b'
			dark
			icon="mdi-home"
			dense
		>
			Hello admin
		</v-alert>
</template>

<script>
export default {}
</script>
